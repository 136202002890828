var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-navigation-drawer",
    {
      class: _vm.$config("settings.layoutColors.sideBarBackground"),
      attrs: {
        "mini-variant": _vm.miniVariant,
        "mini-variant-width": "92",
        app: "",
        clipped: "",
        "expand-on-hover": _vm.$vuetify.breakpoint.mobile ? false : !_vm.drawer,
        value: _vm.$vuetify.breakpoint.mobile ? _vm.drawer : true
      },
      on: {
        "update:miniVariant": function($event) {
          _vm.miniVariant = $event
        },
        "update:mini-variant": function($event) {
          _vm.miniVariant = $event
        },
        input: function($event) {
          return _vm.$emit("update:drawer", $event)
        }
      },
      scopedSlots: _vm._u([
        {
          key: "prepend",
          fn: function() {
            return [
              !_vm.miniVariant
                ? _c(
                    "v-card",
                    {
                      staticClass: "hidden-sm-and-down mb-n1",
                      attrs: {
                        tile: "",
                        flat: "",
                        img: _vm.$config("settings.companyBackgroundImg"),
                        height: "160"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex flex-column py-6 ml-6 d-none" },
                        [
                          _c(
                            "h4",
                            {
                              staticClass:
                                "font-weight-bold primary--text text--darken-1 text-truncate",
                              staticStyle: { "max-width": "220px" }
                            },
                            [
                              _c("v-icon", { attrs: { color: "primary" } }, [
                                _vm._v("mdi-city")
                              ]),
                              _vm._v("Pacific Screening ")
                            ],
                            1
                          ),
                          _c("div", { staticClass: "d-flex justify-start" }, [
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "font-weight-regular primary--text text--darken-2 mt-n1 ml-6"
                              },
                              [_vm._v(_vm._s(_vm.user.name))]
                            )
                          ])
                        ]
                      )
                    ]
                  )
                : _c("v-card", {
                    staticClass: "primary mb-n1",
                    attrs: { height: "160", flat: "", tile: "" }
                  })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-list",
        {
          staticClass: "mr-n2 ml-n2",
          class: _vm.$config("settings.layoutColors.sideBarBackground"),
          attrs: { dense: "", shaped: !_vm.miniVariant, ripple: false }
        },
        [
          _vm._l(_vm.items, function(item, i) {
            return [
              item.hasOwnProperty("group")
                ? _c("sidebar-group", { key: i, attrs: { item: item } })
                : _c("sidebar-item", { key: i, attrs: { item: item } })
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }