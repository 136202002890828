<template>
    <v-navigation-drawer
        :mini-variant.sync="miniVariant"
        mini-variant-width="92"
        app
        :class="$config('settings.layoutColors.sideBarBackground')"
        clipped
        :expand-on-hover="$vuetify.breakpoint.mobile ? false : !drawer"
        :value="$vuetify.breakpoint.mobile ? drawer : true"
        @input="$emit('update:drawer',$event)"
    >
        <template v-slot:prepend>
            <v-card
                class="hidden-sm-and-down mb-n1"
                tile
                flat
                v-if="!miniVariant"
                :img="$config('settings.companyBackgroundImg')"
                height="160"
            >
                <!-- :class="{'d-none': $vuetify.breakpoint.mobile ? false : !drawer }" -->
                <div class="d-flex flex-column py-6 ml-6 d-none">
                    <h4
                        class="font-weight-bold primary--text text--darken-1 text-truncate"
                        style="max-width:220px;"
                    >
                        <v-icon color="primary">mdi-city</v-icon>Pacific Screening
                    </h4>
                    <div class="d-flex justify-start">
                        <h5
                            class="font-weight-regular primary--text text--darken-2 mt-n1 ml-6"
                        >{{user.name}}</h5>
                    </div>
                </div>
            </v-card>
            <v-card v-else height="160" class="primary mb-n1" flat tile></v-card>
        </template>

        <!-- nav -->
        <v-list
            dense
            :class="$config('settings.layoutColors.sideBarBackground')"
            class="mr-n2 ml-n2"
            :shaped="!miniVariant"
            :ripple="false"
        >
            <template v-for="(item, i) in items">
                <sidebar-group v-if="item.hasOwnProperty('group')" :item="item" :key="i"></sidebar-group>
                <sidebar-item v-else :item="item" :key="i"></sidebar-item>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    name: "app-sidebar",
    components: {
        SidebarGroup: () =>
            import("@components/sidebar/components/SidebarGroup"),
        SidebarItem: () => import("@components/sidebar/components/SidebarItem"),
    },
    props: {
        drawer: {
            validator: (val) => {
                return val === null || typeof val === "boolean";
            },
            required: true,
        },
    },

    data() {
        return {
            miniVariant: false,
        };
    },
    computed: {
        ...mapGetters("Sidebar", ["items"]),
        ...mapGetters("User", ["user"]),
    },

    methods: {
        ...mapActions("Sidebar", ["getNavigation"]),
    },
    mounted() {
        this.getNavigation(this.user.role);
    },
};
</script>
